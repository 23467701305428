var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        title: "修改头像",
        maskClosable: false,
        confirmLoading: _vm.confirmLoading,
        width: 800,
      },
    },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { style: { height: "350px" }, attrs: { xs: 24, md: 12 } },
            [
              _c("vue-cropper", {
                ref: "cropper",
                attrs: {
                  img: _vm.options.img,
                  info: true,
                  autoCrop: _vm.options.autoCrop,
                  autoCropWidth: _vm.options.autoCropWidth,
                  autoCropHeight: _vm.options.autoCropHeight,
                  fixedBox: _vm.options.fixedBox,
                },
                on: { realTime: _vm.realTime },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { style: { height: "350px" }, attrs: { xs: 24, md: 12 } },
            [
              _c("div", { staticClass: "avatar-upload-preview" }, [
                _c("img", {
                  style: _vm.previews.img,
                  attrs: { src: _vm.previews.url },
                }),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.cancelHandel } }, [
            _vm._v("取消"),
          ]),
          _c(
            "a-button",
            {
              key: "submit",
              attrs: {
                type: "primary",
                icon: "save",
                loading: _vm.confirmLoading,
              },
              on: { click: _vm.okHandel },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }